<template>
  <div class="goods">
    <!--<van-cell-group>
      <van-notice-bar left-icon="volume-o" :text="notice"/>
    </van-cell-group>-->
    <van-cell-group>
      <van-swipe class="goods-swipe" :autoplay="3000">
        <van-swipe-item v-for="(image, index) in images" :key="index">
          <img :src="image" />
        </van-swipe-item>
      </van-swipe>
    </van-cell-group>
    <van-cell-group>
      <van-search v-model="value" placeholder="请输入搜索关键词" @search="onSearch" />
    </van-cell-group>
    <van-cell-group>
      <van-grid :border="false" :column-num="4">
        <van-grid-item style="text-align:center" onclick="openPlace(1,'【男用户】常见问题')">
          <van-image src="https://xy-1306221339.file.myqcloud.com/app/kefu-icon/boy.png" style="width: 48px;height: 48px"/>
          <span style="font-size: 14px;padding-top: 5px">男用户</span>
        </van-grid-item>
        <van-grid-item style="text-align:center" onclick="openPlace(2,'【女用户】常见问题')">
          <van-image src="https://xy-1306221339.file.myqcloud.com/app/kefu-icon/girl.png" style="width: 48px;height: 48px"/>
          <span style="font-size: 14px;padding-top: 5px">女用户</span>
        </van-grid-item>
        <van-grid-item style="text-align:center" onclick="openPlace(3,'【搭讪聊天】常见问题')">
          <van-image src="https://xy-1306221339.file.myqcloud.com/app/kefu-icon/hello.png" style="width: 48px;height: 48px"/>
          <span style="font-size: 14px;padding-top: 5px">搭讪聊天</span>
        </van-grid-item>
        <van-grid-item style="text-align:center" onclick="openPlace(4,'【提现】常见问题')">
          <van-image src="https://xy-1306221339.file.myqcloud.com/app/kefu-icon/take.png" style="width: 48px;height: 48px"/>
          <span style="font-size: 14px;padding-top: 5px">提现</span>
        </van-grid-item>
        <van-grid-item style="text-align:center" onclick="openPlace(5,'【收益】常见问题')">
          <van-image src="https://xy-1306221339.file.myqcloud.com/app/kefu-icon/income.png" style="width: 48px;height: 48px"/>
          <span style="font-size: 14px;padding-top: 5px">收益</span>
        </van-grid-item>
        <van-grid-item style="text-align:center" onclick="openPlace(6,'【充值】常见问题')">
          <van-image src="https://xy-1306221339.file.myqcloud.com/app/kefu-icon/recharge.png" style="width: 48px;height: 48px"/>
          <span style="font-size: 14px;padding-top: 5px">充值</span>
        </van-grid-item>
        <van-grid-item style="text-align:center" onclick="openPlace(7,'【账号】常见问题')">
          <van-image src="https://xy-1306221339.file.myqcloud.com/app/kefu-icon/user.png" style="width: 48px;height: 48px"/>
          <span style="font-size: 14px;padding-top: 5px">账号</span>
        </van-grid-item>
        <van-grid-item style="text-align:center" onclick="openPlace(8,'【守护】常见问题')">
          <van-image src="https://xy-1306221339.file.myqcloud.com/app/kefu-icon/guard.png" style="width: 48px;height: 48px"/>
          <span style="font-size: 14px;padding-top: 5px">守护</span>
        </van-grid-item>
      </van-grid>
    </van-cell-group>
    <van-cell-group class="goods-cell-group" inset>
      <van-cell title="热门问题" style="font-weight: bold;"/>
      <van-collapse v-model="activeNames" >
        <van-collapse-item v-for="(item,index) in items" :key="index" :name="index">
          <template #title>{{item.name}}</template>
          <template>
            <div class="van-tab__text-wrapper">{{item.content}}</div>
          </template>
        </van-collapse-item>
      </van-collapse>
    </van-cell-group>
    <van-row class="contact">
      <van-button type="primary" class="button" onclick="javascript:window.location.href='xiangyuan://kefu';">联系客服</van-button>
    </van-row>
  </div>
</template>

<script>

import { queryAnswerList } from '@/api/interface'
import { Tag,  Grid,  GridItem,  Col,  Swipe,  SwipeItem,  Cell,  CellGroup,  Icon,  Collapse,
         CollapseItem,  Button,  Image,  Row,  Search,  Toast,  NoticeBar} from 'vant';

export default {
  components: {
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Tag.name]: Tag,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
    [Col.name]: Col,
    [Icon.name]: Icon,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Button.name]: Button,
    [Image.name]: Image,
    [NoticeBar.name]: NoticeBar,
    [Row.name]: Row,
    [Search.name]: Search,
    [Toast.name]: Toast,
  },

  data() {
    return {
      query:{
        place: 0,
        text: ''
      },
      items: [],
      value: '',
      activeNames: ["0"],
      notice: "相缘为泛娱乐平台，所有用户均须严格遵守法律法规政策规定，平台禁止任何用户利用本平台进行任何形式（包括但不限于欺诈、涉黄、涉政、赌博等等）的违法违规行为。用户务必警惕通过添加微信、QQ等第三方软件要求转账的行为。如有疑问请在我的界面【问题反馈】联系客服处理，本平台客服工作时间为:周一~周五10:00-19:00。感谢您的支持。",
      images: [
        'http://qiniu.javachmapi.com/202206050555277479744.jpg',
        'http://qiniu.javachmapi.com/202206051806507981245.jpg'
      ]
    };
  },
  created() {
    this.query.text = this.$route.query.text
    this.value = this.$route.query.text
    this.queryAnswerList();
    window.openPlace = this.openPlace
  },
  methods: {
    queryAnswerList() {
      let _this = this;
      const params = {
        place: this.query.place,
        text: this.query.text,
      }
      queryAnswerList(params).then(res => {
        const array = new Array()
        res.data.data.forEach(function (e) {
          let json = {
            id: e.id,
            name: e.name,
            content: e.content
          };
          array.push(json)
        })
        _this.items = array
      })
    },
    onSearch(val) {
      this.query.text = val
      this.queryAnswerList();
    },
    openPlace(place,name){
      this.$router.push({path:'/list',query: {place:place,name:name,text:this.query.text}})
    }
  }
};
</script>

<style lang="less">
.goods {
  padding-bottom: 50px;

  &-swipe {
    img {
      width: 100%;
      display: block;
    }
  }

  &-title {
    font-size: 16px;
  }

  &-price {
    color: #f44;
  }

  &-express {
    color: #999;
    font-size: 12px;
    padding: 5px 15px;
  }

  &-cell-group {
    margin: 15px 0;

    .van-cell__value {
      color: #999;
    }
  }

  &-tag {
    margin-left: 5px;
  }

  .contact {
    position: center;
    margin: auto;
    left: 0;
    right: 0;
    bottom: 1rem;
    height: 2.5rem;
    text-align: center;
    color: #fff;
    border-radius: .8rem;
    font-size: .5rem;
    border-color: transparent;
  }

  .button {
    width:100px;
    height:35px;
    border-radius:5px;
  }

  .van-tab__text-wrapper{
    white-space: pre-wrap;
  }

}
</style>
